<template>
  <div class="menu__container" :class="{ admin: admin }" v-if="pathName !== '/welcome'">
    <div class="menu__container__link">
      <router-link class="menu__link__logo" to="/welcome">
        <div class="logo">
          <img
            class="img__logo__ingenes img__margin"
            src="../assets/images/logo_blue.png"
            alt="Logo Ingenes"
            v-if="!admin"
          />
          <img class="img__logo__ingenes" src="../assets/images/logo__login.svg" alt="Logo Ingenes" v-else />
        </div>
      </router-link>

      <router-link
        class="menu__link"
        :class="{ 'router-link-active': pathName.includes('/admin') }"
        :to="`/admin/${pathAdmin}`"
        v-if="admin && configRol.administrador.ventana"
      >
        <img src="../assets/images/admin.png" alt="" />
        <span
          >{{ $t('menu.Admin') }}
          <b-badge
            pill
            variant="danger"
            class="mr-2"
            v-if="count_pendings.sol_programas + count_pendings.sol_descuentos"
            >{{ count_pendings.sol_programas + count_pendings.sol_descuentos }}</b-badge
          ></span
        >
      </router-link>
      <router-link class="menu__link" to="/form" v-if="configRol.onboardMedico && configRol.onboardMedico.ventana">
        <img src="../assets/images/receta-icon.svg" alt="" />
        <span>{{ $t('menu.onboarding') }}</span>
      </router-link>
      <router-link class="menu__link" to="/history" v-if="configRol.history.ventana">
        <img src="../assets/images/historico-icon.svg" alt="" />
        <span>{{ $t('menu.History') }}</span>
      </router-link>
      <router-link class="menu__link" to="/proposal" v-if="configRol.sspta.ventana">
        <img src="../assets/images/sspta-icon.svg" alt="" />
        <span>{{ $t('menu.Proposal') }}</span>
      </router-link>
      <router-link class="menu__link" to="/medicine" v-if="configRol.medicina.ventana">
        <img src="../assets/images/medicamento-icon.svg" alt="" />
        <span>{{ $t('menu.Medicine') }}</span>
      </router-link>
      <router-link class="menu__link" to="/cycles" v-if="configRol.cycles.ventana">
        <img src="../assets/images/ciclos-icon.svg" alt="" />
        <span>{{ $t('menu.Cycles') }}</span>
      </router-link>
      <router-link
        class="menu__link"
        :class="{ 'router-link-active': pathName.includes('/recipe') }"
        to="/recipe/sspta"
        v-if="configRol.recipe.ventana"
      >
        <img src="../assets/images/receta-icon.svg" alt="" />
        <span>{{ $t('menu.Recipe') }}</span>
      </router-link>
    </div>
    <div class="menu__container__link link__down" v-if="path !== '/welcome'">
      <div class="menu__link">
        <NavbarLanguage></NavbarLanguage>
      </div>
      <div class="menu__link">
        <button class="login__container__button" @click="handleClickLogOut">
          {{ $t('menu.Logout') }}
        </button>
      </div>
    </div>
  </div>
  <div class="menu__container menu__welcome" v-else>
    <div class="logo">
      <img class="menu__link__welcome" src="../assets/images/logo__login.svg" alt="Logo Ingenes" />
    </div>
    <h6 class="text-white" >{{this.$store.state.imVersion}}</h6>
  </div>
</template>

<script>
import NavbarLanguage from '@/components/NavbarLanguage.vue';
import { notifications } from '../helpers/notifications';

export default {
  name: 'MenuLeft',
  components: {
    NavbarLanguage,
  },
  data: () => ({
    rol: '',
    admin: false,
    isLoading: true,
    pathName: '/welcome',
    timer: null,
    pathAdmin: '',
  }),
  computed: {
    path() {
      return this.$route.path;
    },
    count_pendings() {
      //sonido
      let count = this.$store.state.count_pending;
      if (count) {
        const myNotification = new Audio(require('@/assets/sound/notificacion.mp3'));
        myNotification.play();
      } 
      return this.$store.state.count_pending;
    },
    configRol() {
      return this.$store.state.configRol;
    },
  },
  watch: {
    path() {
      this.pathName = this.path;
    },

    configRol() {
      if (this.configRol.administrador) {
        let keys = Object.keys(this.configRol.administrador.pestana);
        let routes = [
          { name: 'pdf', path: '' },
          { name: 'users', path: 'users' },
          { name: 'pharmacy', path: 'pharmacy' },
          { name: 'auth_programs', path: 'auth' },
          { name: 'trm', path: 'trm' },
          { name: 'medicines', path: 'medicine' },
          { name: 'integrator', path: 'integrator' },
          { name: 'topCards', path: 'top-cards' },
          { name: 'adminRoles', path: 'rol' },
          { name: 'adminSucursales', path: 'bussiness' },
        ];
        for (const el of keys) {
          if (this.configRol.administrador.pestana[el].permiso === true) {
            this.pathAdmin = routes.find((item) => item.name === el).path;
            break;
          }
        }
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);

    if (this.admin) {
      notifications();
      this.timer = setInterval(() => {
        notifications();
      }, 120000);
    }
  },

  methods: {
    handleClickLogOut() {
      this.$store.dispatch('logout');
    },
  },
  created() {
    //Traer estado rol usuario
    this.admin = this.$store.state.rol.toLowerCase().includes('admin');
  },

  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_shared.scss';
@import '@/assets/scss/main.scss';

.menu__container {
  display: flex;
  flex-direction: column;

  background-color: white;
  padding-left: 10%;
  z-index: 10;
  height: 100vh;
  justify-content: space-between;
  &.admin {
    background-color: #003085;
    & .menu__container__link .menu__link {
      color: white;
    }
  }
  & .menu__link__welcome {
    width: 280px;
  }
  & .menu__container__link {
    display: flex;
    flex-direction: column;
    & .menu__link__logo {
      margin-top: 1.5rem;
    }

    & .menu__link {
      text-decoration: none;
      padding: 13px 0;
      color: #b9b9b9;
      font-family: 'futura-heavy';
      font-size: 14px;
      display: flex;
    }

    & .menu__link img {
      width: 32px;
      margin: 0 15px 0 20px;
    }

    & img.img__logo__ingenes {
      width: 200px;
    }

    & img.img__logo__ingenes.img__margin {
      margin-right: -12px;
    }

    & .router-link-active {
      color: #75767a !important;
    }

    & .menu__link.router-link-active {
      position: relative;
      background-color: #f6f6f6;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }

    & .menu__link.router-link-active::before,
    & .menu__link.router-link-active::after {
      content: '';
      position: absolute;
      pointer-events: none;
      background-color: transparent;
      left: 90%;
      height: 50px;
      width: 25px;
    }

    & .menu__link.router-link-active::before {
      border-top-right-radius: 25px;
      bottom: -50px;
      box-shadow: 0 -25px 0 0 #f6f6f6;
    }

    & .menu__link.router-link-active::after {
      border-bottom-right-radius: 25px;
      bottom: 57px;
      box-shadow: 0 25px 0 0 #f6f6f6;
    }
  }

  & .menu__container__link.link__down {
    padding: 0 1rem 0.5rem 0;
  }

  .router-link-active > img {
    filter: invert(30%) sepia(90%) saturate(393%) hue-rotate(42deg) brightness(90%) contrast(87%);
  }

  .none {
    display: none;
  }

  .login__container__button {
    border: 1px solid white;
    width: 100%;
    padding: 6px 0;
    border-radius: 50px;
    color: #003085;
    font-weight: bold;
  }

  .login__container__button:focus {
    outline: none;
  }

  .login__container__button:hover {
    background-color: #003085;
    color: white;
  }
  .logo {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }
}

.menu__welcome {
  background-color: #003085;
}

@media only screen and (max-width: 768px) {
  .menu__container {
    & .menu__link__welcome {
      width: 60px;
    }
    & .menu__container__link {
      & img.img__logo__ingenes {
        width: 60px;
      }

      & img.img__logo__ingenes.img__margin {
        margin-right: -8px;
      }
    }
  }
  .menu__link > span {
    display: none;
  }
}
</style>
