import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue'),
    meta: {
      requireAuth: true /*metadata indicada para proteger rutas*/,
      Administrador: false,
      Normal: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requireAuth: true /*metadata indicada para proteger rutas*/,
      Administrador: false,
      Normal: true,
    },
  },
  {
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue'),
    meta: {
      requireAuth: true /*metadata indicada para proteger rutas*/,
      Administrador: false,
      Normal: true,
    },
  },
  {
    path: '/proposal',
    name: 'Proposal',
    component: () => import(/* webpackChunkName: "proposal" */ '../views/Proposal.vue'),
    meta: {
      requireAuth: true /*metadata indicada para proteger rutas*/,
      Administrador: false,
      Normal: true,
    },
  },
  {
    path: '/medicine',
    name: 'Medicine',
    component: () => import(/* webpackChunkName: "medicine" */ '../views/Medicine.vue'),
    meta: {
      requireAuth: true /*metadata indicada para proteger rutas*/,
      Administrador: false,
      Normal: true,
    },
  },
  {
    path: '/form',
    name: 'Form',
    component: () => import(/* webpackChunkName: "form" */ '../views/Form.vue'),
    meta: {
      requireAuth: true /*metadata indicada para proteger rutas*/,
      Administrador: false,
      Normal: true,
    },
  },
  {
    path: '/cycles',
    name: 'Cycles',
    component: () => import(/* webpackChunkName: "cycles" */ '../views/Cycles.vue'),
    meta: {
      requireAuth: true /*metadata indicada para proteger rutas*/,
      Administrador: false,
      Normal: true,
    },
  },
  {
    path: '/recipe/:name',
    name: 'Recipe',
    component: () => import(/* webpackChunkName: "recipe" */ '../views/Recipe.vue'),
    meta: {
      requireAuth: true /*metadata indicada para proteger rutas*/,
      Administrador: false,
      Normal: true,
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    redirect: { name: 'Editor' },
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    meta: {
      requireAuth: true /*metadata indicada para proteger rutas*/,
      Administrador: true,
    },
    children: [
      // Note we provide the above parent route name on the default child tab
      // route to ensure this tab is rendered by default when using named routes
      {
        path: '',
        name: 'Editor',
        component: () => import(/* webpackChunkName: "editor" */ '../components/Admin/Editor.vue'),
        meta: {
          requireAuth: true /*metadata indicada para proteger rutas*/,
          Administrador: true,
        },
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../components/Admin/Users.vue'),
        meta: {
          requireAuth: true /*metadata indicada para proteger rutas*/,
          Administrador: true,
        },
      },
      {
        path: 'pharmacy',
        name: 'Pharmacy',
        component: () => import(/* webpackChunkName: "pharmacy" */ '../components/Admin/Pharmacy.vue'),
        meta: {
          requireAuth: true /*metadata indicada para proteger rutas*/,
          Administrador: true,
        },
      },
      {
        path: 'auth',
        name: 'Auth',
        component: () => import(/* webpackChunkName: "auth" */ '../components/Admin/Auth.vue'),
        meta: {
          requireAuth: true /*metadata indicada para proteger rutas*/,
          Administrador: true,
        },
      },
      {
        path: 'authdos',
        name: 'Authdos',
        component: () => import(/* webpackChunkName: "auth" */ '../components/Admin/AuthDiscount.vue'),
        meta: {
          requireAuth: true /*metadata indicada para proteger rutas*/,
          Administrador: true,
        },
      },
      {
        path: 'trm',
        name: 'TRM',
        component: () => import(/* webpackChunkName: "trm"*/ '../components/Admin/Trm.vue'),
        meta: {
          requireAuth: true,
          Administrador: true,
        },
      },
      {
        path: 'medicine',
        name: 'MedicineAdmin',
        component: () => import(/* webpackChunkName: "MedicineAdmin" */ '../components/Admin/MedicineAdmin.vue'),
        meta: {
          requireAuth: true /*metadata indicada para proteger rutas*/,
          Administrador: true,
        },
      },
      {
        path: 'integrator',
        name: 'Integrator',
        component: () => import(/* webpackChunkName: "integrator" */ '../components/Admin/Integrator.vue'),
        meta: {
          requireAuth: true, //metadata indicada para proteger rutas
          Administrador: true,
        },
      },
      {
        path: 'top-cards',
        name: 'TopCards',
        component: () => import(/* webpackChunkName: "topCards" */ '../components/Admin/TopCards.vue'),
        meta: {
          requireAuth: true, //metadata indicada para proteger rutas
          Administrador: true,
        },
      },
      {
        path: 'rol',
        name: 'RolAdmin',
        component: () => import(/* webpackChunkName: "rol" */ '../components/Admin/Rol.vue'),
        meta: {
          requireAuth: true, //metadata indicada para proteger rutas
          Administrador: true,
        },
      },
      {
        path: 'bussiness',
        name: 'BussinessAdmin',
        component: () => import(/* webpackChunkName: "bussiness" */ '../components/Admin/Bussiness.vue'),
        meta: {
          requireAuth: true, //metadata indicada para proteger rutas
          Administrador: true,
        },
      },
      {
        path: 'islas&matriz',
        name: 'Islas&Matriz',
        component: () => import(/* webpackChunkName: "islas" */ '../components/Admin/Islas.vue'),
        meta: {
          requireAuth: true, //metadata indicada para proteger rutas
          Administrador: true,
        },
      },
      {
        path: 'adminPrograms',
        name: 'AdminPrograms',
        component: () => import(/* webpackChunkName: "adminPrograms" */ '../components/Admin/AdminPrograms.vue'),
        meta: {
          requireAuth: true, //metadata indicada para proteger rutas
          Administrador: true,
        },
      },
      {
        path: 'adminMac',
        name: 'AdminMac',
        component: () => import('../components/Admin/AdminMac.vue'),
        meta: {
          requireAuth: true,
          Administrador: true,
        },
      },
      {
        path: 'discounts',
        name: 'ListDiscounts',
        component: () => import('../components/Admin/Discount/ListDiscount.vue'),
        meta: {
          requireAuth: true, //metadata indicada para proteger rutas
          Administrador: true,
        },
      },
      {
        path: 'bundles',
        name: 'Bundles',
        component: () => import('../components/Admin/bundles/Bundles.vue'),
        meta: {
          requireAuth: true,
          Administrador: true,
        },
      },
      {
        path: 'ingenes',
        name: 'ProposalIngenes',
        component: () => import('../components/Admin/Ingenes/ListDiscount.vue'),
        meta:{
          requireAuth: true,
          Administrador: true
        },
      }
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch('validate_activity', false);
  if (to.matched.some((record) => record.meta.public)) {
    next();
  } else if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
    const user = store.state.userResponse; //usuario actual Esta Logueado
    if (!user) {
      next({ name: 'Login' });
    } else if (
      // si el usuario es admin
      user &&
      to.matched.some((ruta) => ruta.meta.Administrador) &&
      store.state.rol.toLowerCase().includes('admin')
    ) {
      next();
    } else if (user && to.matched.some((ruta) => ruta.meta.Normal)) {
      // si el usuario es de acceso

      setTimeout(() => {
        store.dispatch('validate_activity', true);
      }, 1000);
      if (sessionStorage.getItem('email') || to.name === 'Welcome') {
        next();
      } else {
        next({ name: 'Welcome' });
      }
    } else {
      next({ name: 'Welcome' });
    }
  } else {
    next({ name: 'Login' });
  }
});

export default router;
