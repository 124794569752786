import { ApiRequest } from "../helpers/ApiRequest";
import { API_HUBSPOT, API_ROOT } from "../Constants/Api";

export const commonPetition = {
  async optionsBranch({ commit }, country) {
    try {
      let mx, mxBranch, ciudadMX, destinoMX;
      //let us, usBranch, usBranches, ciudadesUS, destinoUS;
      let us, usBranch,  destinoUS;

      let data = await ApiRequest.get(`${API_HUBSPOT}sedes`);
      let sedesDestino = data.sedes.find(
        (el) => el.prop_name === "sede_destino"
      );

      let dataUS = await ApiRequest.get(`${API_ROOT}islaMatriz/us`);
      //let sedesUSA = dataUS

      if (country === "MX") {
        mx = await ApiRequest.get(`${API_HUBSPOT}sucursalesMX`);
        ciudadMX = mx.sucursales;
        mxBranch = mx.sucursales.map((el) => ({
          text: el,
          value: el,
          country: "mx",
        }));
        destinoMX = sedesDestino.datos
          .filter((el) => ciudadMX.includes(el.label))
          .map((el) => ({ text: el.label, value: el.value, country: "mx" }));
        
        

        commit("setBranch", { mxBranch, destinoMX });
      } 
      else if (country === "US") {
        us = await ApiRequest.get(`${API_HUBSPOT}sucursalesUS`);
        //ciudadUS = us.sucursales;
        usBranch = us.sucursales.map((el) => ({
          text: el,
          value: el,
          country: "us",
        }));
      
        // Mapear correctamente los datos de dataUS
        destinoUS = dataUS.sedes
          //.filter((el) => ciudadUS.includes(el.value)) // Comparar con el array de sucursales de us
          .map((el) => ({ text: el.label, value: el.value, country: "us" }));
      
        commit("setBranch", { usBranch, destinoUS });
      }

            // else if (country === "US") {
      //   us = await ApiRequest.get(`${API_HUBSPOT}sucursalesUS`);
      //   ciudadUS = us.sucursales;
      //   usBranch = us.sucursales.map((el) => ({
      //     text: el,
      //     value: el,
      //     country: "us",
      //   }));

      //   destinoUS = sedesDestino.datos
      //     .filter((el) => ciudadUS.includes(el.label))
      //     .map((el) => ({ text: el.label, value: el.value, country: "us" }));

      //   commit("setBranch", { usBranch, destinoUS });
      // }


      
    } catch (err) {
      throw new Error(JSON.stringify(err));
    }
  },
  async getProductsXCountry({ commit }, pais) {
    try {
      const data = await ApiRequest.post(
        `${API_ROOT}cards/productosCategoriaPais`,
        {
          localidad: pais === "US" ? 2 : 1, // MX es 1
        }
      );
      let allProducts = data.categorias_productos;
      commit("setProductsXCountry", allProducts);
    } catch (err) {
      throw new Error(JSON.stringify(err));
    }
  },

};
