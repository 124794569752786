var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pathName !== "/welcome"
    ? _c(
        "div",
        { staticClass: "menu__container", class: { admin: _vm.admin } },
        [
          _c(
            "div",
            { staticClass: "menu__container__link" },
            [
              _c(
                "router-link",
                { staticClass: "menu__link__logo", attrs: { to: "/welcome" } },
                [
                  _c("div", { staticClass: "logo" }, [
                    !_vm.admin
                      ? _c("img", {
                          staticClass: "img__logo__ingenes img__margin",
                          attrs: {
                            src: require("../assets/images/logo_blue.png"),
                            alt: "Logo Ingenes"
                          }
                        })
                      : _c("img", {
                          staticClass: "img__logo__ingenes",
                          attrs: {
                            src: require("../assets/images/logo__login.svg"),
                            alt: "Logo Ingenes"
                          }
                        })
                  ])
                ]
              ),
              _vm.admin && _vm.configRol.administrador.ventana
                ? _c(
                    "router-link",
                    {
                      staticClass: "menu__link",
                      class: {
                        "router-link-active": _vm.pathName.includes("/admin")
                      },
                      attrs: { to: "/admin/" + _vm.pathAdmin }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/admin.png"),
                          alt: ""
                        }
                      }),
                      _c(
                        "span",
                        [
                          _vm._v(_vm._s(_vm.$t("menu.Admin")) + " "),
                          _vm.count_pendings.sol_programas +
                          _vm.count_pendings.sol_descuentos
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "mr-2",
                                  attrs: { pill: "", variant: "danger" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.count_pendings.sol_programas +
                                        _vm.count_pendings.sol_descuentos
                                    )
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.configRol.onboardMedico && _vm.configRol.onboardMedico.ventana
                ? _c(
                    "router-link",
                    { staticClass: "menu__link", attrs: { to: "/form" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/receta-icon.svg"),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("menu.onboarding")))])
                    ]
                  )
                : _vm._e(),
              _vm.configRol.history.ventana
                ? _c(
                    "router-link",
                    { staticClass: "menu__link", attrs: { to: "/history" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/historico-icon.svg"),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("menu.History")))])
                    ]
                  )
                : _vm._e(),
              _vm.configRol.sspta.ventana
                ? _c(
                    "router-link",
                    { staticClass: "menu__link", attrs: { to: "/proposal" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/sspta-icon.svg"),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("menu.Proposal")))])
                    ]
                  )
                : _vm._e(),
              _vm.configRol.medicina.ventana
                ? _c(
                    "router-link",
                    { staticClass: "menu__link", attrs: { to: "/medicine" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/medicamento-icon.svg"),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("menu.Medicine")))])
                    ]
                  )
                : _vm._e(),
              _vm.configRol.cycles.ventana
                ? _c(
                    "router-link",
                    { staticClass: "menu__link", attrs: { to: "/cycles" } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/ciclos-icon.svg"),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("menu.Cycles")))])
                    ]
                  )
                : _vm._e(),
              _vm.configRol.recipe.ventana
                ? _c(
                    "router-link",
                    {
                      staticClass: "menu__link",
                      class: {
                        "router-link-active": _vm.pathName.includes("/recipe")
                      },
                      attrs: { to: "/recipe/sspta" }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/receta-icon.svg"),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("menu.Recipe")))])
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.path !== "/welcome"
            ? _c("div", { staticClass: "menu__container__link link__down" }, [
                _c(
                  "div",
                  { staticClass: "menu__link" },
                  [_c("NavbarLanguage")],
                  1
                ),
                _c("div", { staticClass: "menu__link" }, [
                  _c(
                    "button",
                    {
                      staticClass: "login__container__button",
                      on: { click: _vm.handleClickLogOut }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("menu.Logout")) + " ")]
                  )
                ])
              ])
            : _vm._e()
        ]
      )
    : _c("div", { staticClass: "menu__container menu__welcome" }, [
        _vm._m(0),
        _c("h6", { staticClass: "text-white" }, [
          _vm._v(_vm._s(this.$store.state.imVersion))
        ])
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        staticClass: "menu__link__welcome",
        attrs: {
          src: require("../assets/images/logo__login.svg"),
          alt: "Logo Ingenes"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }