<template>
  <div v-if="this.$store.state.userResponse">
    <aside class="menu__left"><MenuLeft /></aside>
    <div class="container__main"><router-view /></div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import MenuLeft from '@/components/MenuLeft';
import { AlertConfirm, ICON } from './helpers/Alert';

export default {
  name: 'App',
  components: {
    MenuLeft,
  },
  data() {
    return {
      timeInactivity: '',
    };
  },
  async created() {
    this.$store.dispatch('autoLogin');

    this.$i18n.locale = sessionStorage.getItem('country') === 'MX' ? 'es' : 'en';
    if (process.env.VUE_APP_VERSION) localStorage.setItem('version', process.env.VUE_APP_VERSION);
  },
  computed: {
    inactivity() {
      return this.$store.state.inactivity;
    },
  },
  watch: {
    inactivity() {
      if (this.timeInactivity) clearTimeout(this.timeInactivity);
      if (this.inactivity) {
        let time = process.env.VUE_APP_TIME_INACTIVITY || 20;
        time = Number(time) * 60000;
        this.timeInactivity = setTimeout(() => {
          AlertConfirm(
            this.$i18n.t('textInactivity'),
            '',
            ICON.WARNING,
            () => {
              this.$router.push({ name: 'Welcome' });
              this.$store.dispatch('validate_activity', false);
              location.reload();
            },
            this.$i18n.t('acceptText'),
            false
          );
        }, time);
      }
    },
  },
};
</script>
<style lang="scss">
@import '~normalize.css';

.menu__left {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 100;
  width: 270px;
}

.container__main {
  position: relative;
  top: 0;
  left: 300px;
  width: 80%;
  width: calc(100% - 300px);
}

// style SCROLL
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);

  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

// GLOBAL STYLES TABLES
.table-head-PackageMedicine {
  display: grid;
  grid-template-columns: 1fr 100px 50px 50px;
}

.table-row,
.table-row-title-action {
  display: grid;
  grid-template-columns: 1fr 50px;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  margin-top: 14px;
  font-weight: bold;
}

/**TABLE AUTH AND PACKAGE MEDICINE */
.table-head-Auth {
  display: grid;
  align-items: center;
}

.table-itemPackage,
.table-body-Auth {
  display: grid;
  grid-template-columns: 50px 1fr 100px 80px 50px;
  margin: 10px 10px 0 10px;
  background: white;
  border: 1px solid #e2dede;
  border-bottom: none;
  border-radius: 0.5rem;
  font-weight: bold;
}

.table-body-Auth,
.table-head-Auth {
  grid-template-columns: 0.8fr 1.8fr 0.7fr 1fr 1.4fr 1.1fr;
}

.table-cards {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr 0.2fr;
}
.table-role {
  display: grid;
  grid-template-columns: 0.7fr 0.5fr 1.5fr 230px;
}

.table-matriz-product {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 150px;
}
.b-table-details.table-body-Auth {
  margin-top: 0;
  border-radius: 0 0 0.5rem 0.5rem;
  border-top: none;
  font-weight: 400;
}

.b-table-details.table-itemPackage,
.b-table-details.table-body-Auth {
  display: grid;
  grid-template-columns: 1fr;
}

.b-table-details.table-itemPackage,
.b-table-details.table-body-Auth {
  background-color: #ffffff;
}

.table-head-detailMedicine,
.table-body-detailMedicine {
  display: grid;
  grid-template-columns: 1fr 100px 100px;
  padding-left: 40px;
}
.table-body-hubspot,
.table-head-hubspot {
  display: grid;
  grid-template-columns: 1fr 1fr 0.7fr;
}

.table-body-hubspot > td {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-category-bussiness {
  //display: grid;
  grid-template-columns: repeat(5, 120px) 1fr;
}
// DETAILS TABLE
.b-table-details {
  background-color: #f5f5f5;
}

.b-table-details > td {
  padding: 0;
}

//tabla al seleccionar item row
.table-active {
  background-color: transparent !important;
}

//.sweet alert

.swal2-title {
  overflow-wrap: anywhere !important;
}


.b-ingen{
  text-align: left !important;
}
.ingenes-class{
  background: #003085;
  color: #fff;
  padding: 0 5px;
  border-radius: 6px;
  text-align: left !important;
  display: block;
}
.ingenes-class b{
  float: right;
}
.ingenes-class b span{
  color: rgb(255 255 255 / 75%) !important;
}
.no-ingen, b span.no-ingen{
  text-align: left !important;
}
.no-ingen label{
  display: block;
}
.no-ingen b, .no-ingen label b span{
  float: right;
}

@media only screen and (max-width: 768px) {
  .container__main {
    left: 100px;
    width: 80%;
    width: calc(100vw - 100px);
  }
  .menu__left {
    width: 70px;
  }
}
</style>
