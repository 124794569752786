export const API_ROOT = process.env.VUE_APP_API;

export const API_HUBSPOT = `${API_ROOT}hubspot/`;

export const API_PACIENTE_PROPUESTA = `${API_HUBSPOT}validarPacientePropuesta/`;

export const API_UPLOAD_FILE = `${API_ROOT}uploadFile/`;

export const API_PRODUCTO_CATEGORIA = `${API_ROOT}productos/categoria/`;

export const API_GOOGLE_AUTH = `${API_ROOT}auth/gooauth`;

export const API_ROOT_CENTRAL = process.env.VUE_APP_API_CENTRAL;

export const API_CENTRAL = `${API_ROOT_CENTRAL}processrequest/`;