<template>
    <div>
    <p> {{ $t('Language') }}: </p>
    <b-form-select v-model="selected" :options="options" size="sm" class="language"></b-form-select>
    </div>
</template>

<script>
    export default {
      name: 'NavbarLanguage',
      data() {
          return {
          selected: this.$i18n.locale,
          options: [
            { value: 'en', text: 'English'},
            { value: 'es', text: 'Español' },
          ]
      }},
      watch: {
        selected: function () {
              if(this.selected == "es"){
                  this.$i18n.locale = "es";
              }else{
                  this.$i18n.locale = "en";
              }
        }
      }
    };
</script>
<style>
    .language {
        width: 150px;
    }
</style>
