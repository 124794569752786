export const initialForm = {
    administrador: {
        ventana: false, // puede acceder a la pestaña o esta oculta
        pestana: {
            pdf: {
                permiso: false,
                acciones: {
                    editor: false,
                    imagenes: false,
                    guardar: false
                }
            },
            users: {
                permiso: false,
                acciones: {
                    listar: false,
                    crear: false,
                    editar: false,
                }
            },
            pharmacy: {
                permiso: false,
                acciones: {
                    listar: false,
                    crear: false,
                    editar: false,
                    eliminar: false
                }
            },
            auth_programs: {
                permiso: false,
                acciones: {
                    listar: false,
                    aceptar: false,
                    rechazar: false,
                }
            },
            auth_discounts: {
                permiso: false,
                acciones: {
                    listar: false,
                    aceptar: false,
                    rechazar: false,
                }
            },
            medicines: {
                permiso: false,
                acciones: {
                    listar: false,
                    crearFiltro: false,
                    crearAgrupador: false,
                    editarAgrupador: false,
                    eliminarAgrupador: false
                }
            },
            integrator: {
                permiso: false,
                acciones: {
                    listar: false,
                    crear: false,
                    editar: false,
                    eliminar: false
                }
            },
            topCards: {
                permiso: false,
                acciones: {
                    listar: false,
                    crear: false,
                    editar: false,
                    eliminar: false
                }
            },
            trm: {
                permiso: false,
                acciones: {
                    listar: false,
                    editar: false,
                }
            },
            adminRoles: {
                permiso: false,
                acciones: {
                    listarRol: false,
                    crearRol: false,
                    editarRol: false,
                    eliminarRol: false,
                    listarConfigRol: false,
                    crearConfigRol: false,
                    editarConfigRol: false,
                    clonarRol: false,
                    eliminarConfigRol: false
                }
            },
            adminSucursales: {
                permiso: false,
                acciones: { // por definir la estructura
                    listar: false,
                    crear: false,
                    clonar: false,
                    editar: false,
                    eliminar: false
                }
            },
            branchMatrix: {
                permiso: false,
                acciones: { // por definir la estructura
                    listar: false,
                    crear: false,
                    eliminar: false
                }
            },
            adminDiscounts: {
                permiso:false,
                acciones: {
                    listar: false,
                    crear: false,
                    clonar: false,
                    editar: false,
                    eliminar: false,
                }
            }
        }
    },
    sspta: {
        ventana: false,
        pestana: {
            technics: false,
            programs: false,
            labs: false,
            surgeries: false,
            treatments: false,
            medicinesIndividual: false,
            medicinesPackage: false,
        }
    },
    history: {
        ventana: false,
    },
    medicina: {
        ventana: false,
    },
    cycles: {
        ventana: false,
    },
    recipe: {
        ventana: false,
    },
    onboardMedico:{
        ventana:false,
    }
}

export const initialAdmin = (self) => {
    return  [
        {
            name: self.$i18n.t("admin.role.pdf"),
            key: "pdf",
            actions: [
                { name: self.$i18n.t("admin.role.showPdf"), key: "editor" },
                { name: self.$i18n.t("admin.role.imgPDF"), key: "imagenes" },
                { name: self.$i18n.t("admin.role.save"), key: "guardar" }
            ]
        },
        {
            name: self.$i18n.t("admin.role.userManagement"),
            key: "users",
            actions: [
                { name: self.$i18n.t("admin.role.list"), key: "listar" },
                { name: self.$i18n.t("admin.role.create"), key: "crear" },
                { name: self.$i18n.t("admin.role.edit"), key: "editar" },
            ]
        },
        {
            name: self.$i18n.t("admin.role.pharmacy"),
            key: "pharmacy",
            actions: [
                { name: self.$i18n.t("admin.role.list"), key: "listar" },
                { name: self.$i18n.t("admin.role.create"), key: "crear" },
                { name: self.$i18n.t("admin.role.edit"), key: "editar" },
                { name: self.$i18n.t("admin.role.delete"), key: "eliminar" }
            ]
        },
        {
            name: self.$i18n.t("admin.role.authPrograms"),
            key: "auth_programs",
            actions: [
                { name: self.$i18n.t("admin.role.list"), key: "listar" },
                { name: self.$i18n.t("admin.role.accept"), key: "aceptar" },
                { name: self.$i18n.t("admin.role.reject"), key: "rechazar" }
            ]
        },
        {
            name: self.$i18n.t("admin.role.authDiscounts"),
            key: "auth_discounts",
            actions: [
                { name: self.$i18n.t("admin.role.list"), key: "listar" },
                { name: self.$i18n.t("admin.role.accept"), key: "aceptar" },
                { name: self.$i18n.t("admin.role.reject"), key: "rechazar" }
            ]
        },
        {
            name: self.$i18n.t("admin.role.adminMed"),
            key: "medicines",
            actions: [
                { name: self.$i18n.t("admin.role.listGrouper"), key: "listar" },
                { name: self.$i18n.t("admin.role.createFilter"), key: "crearFiltro" },
                { name: self.$i18n.t("admin.role.createGrouper"), key: "crearAgrupador" },
                { name: self.$i18n.t("admin.role.editGrouper"), key: "editarAgrupador" },
                { name: self.$i18n.t("admin.role.change"), key: "eliminarAgrupador" },
            ]
        },
        {
            name: self.$i18n.t("admin.role.integrator"),
            key: "integrator",
            actions: [
                { name: self.$i18n.t("admin.role.list"), key: "listar" },
                { name: self.$i18n.t("admin.role.create"), key: "crear" },
                { name: self.$i18n.t("admin.role.edit"), key: "editar" },
                { name: self.$i18n.t("admin.role.delete"), key: "eliminar" }
            ]
        },
        {
            name: "Top 10 Cards",
            key: "topCards",
            actions: [
                { name: self.$i18n.t("admin.role.list"), key: "listar" },
                { name: self.$i18n.t("admin.role.create"), key: "crear" },
                { name: self.$i18n.t("admin.role.edit"), key: "editar" },
                { name: self.$i18n.t("admin.role.delete"), key: "eliminar" }
            ]
        },
        {
            name: "TRM",
            key: "trm",
            actions: [
                { name: self.$i18n.t("admin.role.list"), key: "listar" },
                { name: self.$i18n.t("admin.role.edit"), key: "editar" },

            ]
        },
        {
            name: self.$i18n.t("admin.role.title"),
            key: "adminRoles",
            actions: [
                { name: self.$i18n.t("admin.role.listRole"), key: "listarRol" },
                { name: self.$i18n.t("admin.role.createRole"), key: "crearRol" },
                { name: self.$i18n.t("admin.role.editRole"), key: "editarRol" },
                { name: self.$i18n.t("admin.role.deleteRole"), key: "eliminarRol" },
                { name: self.$i18n.t("admin.role.listConf"), key: "listarConfigRol" },
                { name: self.$i18n.t("admin.role.createConfRole"), key: "crearConfigRol" },
                { name: self.$i18n.t("admin.role.editConf"), key: "editarConfigRol" },
                { name: self.$i18n.t("admin.role.cloneConf"), key: "clonarRol" },
                { name: self.$i18n.t("admin.role.deleteConf"), key: "eliminarConfigRol" },
            ]
        },
        {
            name: self.$i18n.t("admin.role.titleBusiness"),
            key: "adminSucursales",
            actions: [
                { name: self.$i18n.t("admin.role.list"), key: "listar" },
                { name: self.$i18n.t("admin.role.create"), key: "crear" },
                { name: self.$i18n.t("admin.role.edit"), key: "editar" },
                { name: self.$i18n.t("admin.role.delete"), key: "eliminar" },
                { name: self.$i18n.t("admin.role.clone"), key: "clonar" },

            ]
        },
        {
            name: `${self.$i18n.t("admin.branchMatriz.branch")} & ${self.$i18n.t("admin.branchMatriz.matrix")}`,
            key: "branchMatrix",
            actions: [
                { name: self.$i18n.t("admin.role.list"), key: "listar" },
                { name: `${self.$i18n.t("admin.role.create")} - ${self.$i18n.t("admin.role.edit")}`, key: "crear" },
                { name: self.$i18n.t("admin.role.delete"), key: "eliminar" },
            ]
        },
        {
            name: self.$i18n.t("admin.role.titleDiscounts"),
            key: "adminDiscounts",
            actions: [
                { name: self.$i18n.t("admin.role.list"), key: "listar" },
                { name: self.$i18n.t("admin.role.create"), key: "crear" },
                { name: self.$i18n.t("admin.role.edit"), key: "editar" },
                { name: self.$i18n.t("admin.role.delete"), key: "eliminar" },
                { name: self.$i18n.t("admin.role.clone"), key: "clonar" },

            ]
        },
    ]
}

export const defaultConfigRol = {
    administrador: {
        ventana: false, // puede acceder a la pestaña o esta oculta
        pestana: {
            pdf: {
                permiso: false,
                acciones: {
                    editor: false,
                    imagenes: false,
                    guardar: false
                }
            },
            users: {
                permiso: false,
                acciones: {
                    listar: false,
                    crear: false,
                    editar: false,
                }
            },
            pharmacy: {
                permiso: false,
                acciones: {
                    listar: false,
                    crear: false,
                    editar: false,
                    eliminar: false
                }
            },
            auth_programs: {
                permiso: false,
                acciones: {
                    listar: false,
                    aceptar: false,
                    rechazar: false,
                }
            },
            medicines: {
                permiso: false,
                acciones: {
                    listar: false,
                    crearFiltro: false,
                    crearAgrupador: false,
                    editarAgrupador: false,
                    eliminarAgrupador: false
                }
            },
            integrator: {
                permiso: false,
                acciones: {
                    listar: false,
                    crear: false,
                    editar: false,
                    eliminar: false
                }
            },
            topCards: {
                permiso: false,
                acciones: {
                    listar: false,
                    crear: false,
                    editar: false,
                    eliminar: false
                }
            },
            trm: {
                permiso: false,
                acciones: {
                    listar: false,
                    editar: false,
                }
            },
            adminRoles: {
                permiso: false,
                acciones: {
                    listarRol: false,
                    crearRol: false,
                    editarRol: false,
                    eliminarRol: false,
                    listarConfigRol: false,
                    crearConfigRol: false,
                    editarConfigRol: false,
                    clonarRol: false,
                    eliminarConfigRol: false
                }
            },
            adminSucursales: {
                permiso: false,
                acciones: { // por definir la estructura
                    listar: false,
                    crear: false,
                    clonar: false,
                    editar: false,
                    eliminar: false
                }
            },
            branchMatrix: {
                permiso: false,
                acciones: { // por definir la estructura
                    listar: false,
                    crear: false,
                    eliminar: false
                }
            },
            adminDiscounts: {
                permiso:false,
                acciones: {
                    listar: false,
                    crear: false,
                    clonar: false,
                    editar: false,
                    eliminar: false,
                }
            }
        }
    },
    sspta: {
        ventana: false,
        pestana: {
            technics: false,
            programs: false,
            labs: false,
            surgeries: false,
            treatments: false,
            medicinesIndividual: false,
            medicinesPackage: false,
        }
    },
    history: {
        ventana: false,
    },
    medicina: {
        ventana: false,
    },
    cycles: {
        ventana: false,
    },
    recipe: {
        ventana: false,
    },
    onboardMedico:{
        ventana:false,
    }
}