var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.$store.state.userResponse
    ? _c("div", [
        _c("aside", { staticClass: "menu__left" }, [_c("MenuLeft")], 1),
        _c("div", { staticClass: "container__main" }, [_c("router-view")], 1)
      ])
    : _c("div", [_c("router-view")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }