import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import GAuth from "vue-google-oauth2";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueLuxon from "vue-luxon";
import VueI18n from "vue-i18n";
import { messages } from "./locale/traductor";
import store from "./store";

// Vue I18n is internationalization plugin of Vue.js. It easily integrates some localization features to your Vue.js Application.
Vue.use(VueI18n);
// data time
Vue.use(VueLuxon);

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/scss/main.scss";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

if (window.location.hostname !== "localhost") {
  Vue.use(GAuth, {
    clientId:
      "983701630814-bdu25pc43kkhql3rg8j7jjd78ss8le5p.apps.googleusercontent.com",
    scope: "email",
    prompt: "consent",
    fetch_basic_profile: true,
  });
}

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en",
  messages, // set locale messages
});

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
