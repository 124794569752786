import axios from 'axios';
import store from '../store';

export const ApiRequest = {
  async get(url) {
    try {
      let { data } = await axios.get(url, {
        responseType: 'json',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token') || process.env.VUE_APP_TOKEN}`,
          version: localStorage.getItem('version') || process.env.VUE_APP_VERSION,
        },
      });
      if (data.ok === false)
        throw { response: { status: 'Data Failure', statusText: data.msg || `Error API: ${url}` } };
      return data;
    } catch (error) {
      if (error.response.status === 403 && error.response.data?.msg === 'Versión no valida') {
        store.dispatch('logout', 'version');
        throw {
          status: error.response.status,
          statusText: `Version No valida: Por favor iniciar sesion nuevamente`,
          data: error.response.data || {},
        };
      }
      throw {
        status: error.response.status,
        statusText: error.response.data?.msg || error.response.statusText || `Error API: ${url}`,
        data: error.response.data || {},
      };
    }
  },
  async post(url, body) {
    try {
      let { data } = await axios.post(
        url,
        { ...body, userEmail: store.state.userResponse.email }, //se agrega email de usuario logueado con propositos de verificacion de las peticiones realizadas
        {
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || process.env.VUE_APP_TOKEN}`,
            version: localStorage.getItem('version') || '',
          },
        }
      );
      if (data.ok === false)
        throw { response: { status: 'Data Failure', statusText: data.msg || `Error API: ${url}` } };
      return data;
    } catch (error) {
      if (error.response.status === 403 && error.response.data?.msg === 'Versión no valida') {
        store.dispatch('logout', 'version');
        throw {
          status: error.response.status,
          statusText: `Version No valida: Por favor iniciar sesion nuevamente`,
          data: error.response.data || {},
        };
      }
      throw {
        status: error.response.status,
        statusText: error.response.data?.msg || error.response.statusText || `Error API: ${url}`,
        data: error.response.data || {},
      };
    }
  },
  async put(url, body) {
    try {
      let { data } = await axios.put(url, body, {
        responseType: 'json',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token') || process.env.VUE_APP_TOKEN}`,
          version: localStorage.getItem('version') || '',
        },
      });
      if (data.ok === false)
        throw { response: { status: 'Data Failure', statusText: data.msg || `Error API: ${url}` } };
      return data;
    } catch (error) {
      if (error.response.status === 403 && error.response.data?.msg === 'Versión no valida') {
        store.dispatch('logout', 'version');
        throw {
          status: error.response.status,
          statusText: `Version No valida: Por favor iniciar sesion nuevamente`,
          data: error.response.data || {},
        };
      }
      throw {
        status: error.response.status,
        statusText: error.response.data?.msg || error.response.statusText || `Error API: ${url}`,
        data: error.response.data || {},
      };
    }
  },
  async delete(url, body) {
    try {
      let { data } = await axios.delete(
        url,
        {
          data: body,
        },
        {
          responseType: 'json',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token') || process.env.VUE_APP_TOKEN}`,
            version: localStorage.getItem('version') || '',
          },
        }
      );
      if (data.ok === false)
        throw { response: { status: 'Data Failure', statusText: data.msg || `Error API: ${url}` } };
      return data;
    } catch (error) {
      if (error.response.status === 403 && error.response.data?.msg === 'Versión no valida') {
        store.dispatch('logout', 'version');
        throw {
          status: error.response.status,
          statusText: `Version No valida: Por favor iniciar sesion nuevamente`,
          data: error.response.data || {},
        };
      }
      throw {
        status: error.response.status,
        statusText: error.response.data?.msg || error.response.statusText || `Error API: ${url}`,
        data: error.response.data || {},
      };
    }
  },
  async publicPost(url, body, headers = {}) {
    try {
      let { data } = await axios.post(url, body, {
        responseType: 'json',
        headers: {
          ...headers,
        },
      });
      if (data.ok === false)
        throw { response: { status: 'Data Failure', statusText: data.msg || `Error API: ${url}` } };
      return data;
    } catch (error) {
      console.log(error);
      throw {
        status: error.response.status,
        statusText: error.response.data?.msg || error.response.statusText || `Error API: ${url}`,
        data: error.response.data || {},
      };
    }
  },
};
