import { API_ROOT } from "../Constants/Api";
import store from '../store'
import { ApiRequest } from "./ApiRequest";

export const notifications = async () => {
    try {
      const count = await ApiRequest.get(
        `${API_ROOT}solicitudesProgramas/count_pendings`
      );
      store.dispatch("count_pendings", count.conteo_pendientes);
    } catch (error) {
      console.log(error);
    }
  }